import Image from 'next/image';
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import HomeCTA from 'components/Buttons/StandardCTA';

import * as Styles from './styles';

export default function FluencyJourney({ hideCTA }) {
  const theme = useTheme();

  return (
    <Box
      component="section"
      id="how-it-works"
      bgcolor="background.secondary.main"
    >
      <Container maxWidth="xl" sx={Styles.SectionWrapper} disableGutters>
        <Grid container alignItems="center">
          <Grid item xs={12} md={5}>
            <Typography component="h3" variant="h4" fontWeight={700}>
              Como funciona a jornada integrada da fluência na{' '}
              <Box
                component="span"
                sx={{ color: theme.palette.background.primary.main }}
              >
                Fluencypass
              </Box>
            </Typography>
            <List disablePadding sx={{ mt: 5 }}>
              <ListItem disablePadding sx={{ mb: 5 }}>
                <Box mr={2}>
                  <Image
                    alt="Icone das aulas online"
                    height={58}
                    loader={({ src, width }) => `${src}?w=${width}`}
                    src="https://res.cloudinary.com/intercambiodireto-com/f_auto,c_limit,w_128,q_auto/Others/Icons/icon-class-2x-64x64_udotmm"
                    width={58}
                  />
                </Box>
                <Typography variant="subtitle1" component="p">
                  <Box
                    component="span"
                    sx={{
                      fontWeight: 700,
                      color: theme.palette.productsColor.class,
                    }}
                  >
                    Aprenda inglês
                  </Box>{' '}
                  de forma rápida, leve e descomplicada com os melhores
                  professores do mundo.
                </Typography>
              </ListItem>

              <ListItem disablePadding sx={{ mb: 5 }}>
                <Box mr={2}>
                  <Image
                    alt="Icone de conversação"
                    height={58}
                    loader={({ src, width }) => `${src}?w=${width}`}
                    src="https://res.cloudinary.com/intercambiodireto-com/f_auto,c_limit,w_128,q_auto/Others/Icons/icon-talk-2x-64x64_k3krgd"
                    width={58}
                  />
                </Box>
                <Typography variant="subtitle1" component="p">
                  <Box
                    component="span"
                    sx={{
                      fontWeight: 700,
                      color: theme.palette.productsColor.talk,
                    }}
                  >
                    Coloque em prática
                  </Box>{' '}
                  o que você aprendeu em conversação em grupo e aulas
                  particulares para não travar na hora de falar.
                </Typography>
              </ListItem>

              <ListItem disablePadding sx={{ mb: 5 }}>
                <Box mr={2}>
                  <Image
                    alt="Icone de intercambio"
                    height={58}
                    loader={({ src, width }) => `${src}?w=${width}`}
                    src="https://res.cloudinary.com/intercambiodireto-com/f_auto,c_limit,w_128,q_auto/Others/Icons/pass-2x-64x64_jp5ozc"
                    width={58}
                  />
                </Box>
                <Typography variant="subtitle1" component="p">
                  <Box
                    component="span"
                    sx={{
                      fontWeight: 700,
                      color: theme.palette.productsColor.abroad,
                    }}
                  >
                    Faça um intercâmbio
                  </Box>{' '}
                  para praticar o idioma e viver a melhor experiência da sua
                  vida.
                </Typography>
              </ListItem>
            </List>
            {!hideCTA && <HomeCTA />}
          </Grid>

          <Grid item xs={12} md={7}>
            <Box
              sx={{
                position: 'relative',
                height: { sm: '470px', lg: '540px' },
              }}
            >
              <Image
                alt="Teoria, Pratica e imersão"
                fill
                style={{ objectFit: 'contain' }}
                loader={({ src, width }) => `${src}?w=${width}`}
                sizes="(max-width: 768px) 100vw, (max-width: 1280px) 50vw"
                src="https://res.cloudinary.com/intercambiodireto-com/image/upload/f_auto/v1700832003/Others/Home/jornada-fp_fv8oua"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
