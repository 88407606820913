import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useWebApp } from 'providers/WebAppProvider';
import WebAppOperationNotAllowed from 'components/WebAppOperationNotAllowed';

StandardCTA.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  smooth: PropTypes.bool,
  target: PropTypes.string,
  text: PropTypes.string,
  isBlackNovember: PropTypes.bool,
};

StandardCTA.defaultProps = {
  href: '/ingles-online',
  onClick: null,
  smooth: false,
  target: '',
  text: 'Quero saber mais',
};

export default function StandardCTA({
  text,
  href,
  smooth,
  target,
  onClick,
  isBlackNovember = false,
}) {
  const router = useRouter();
  const runningInWebView = useWebApp();

  return (
    <Button
      variant="contained"
      color="success"
      size="large"
      onClick={() => {
        if (runningInWebView) {
          WebAppOperationNotAllowed();
          return;
        }

        if (onClick) {
          onClick();

          return;
        }

        if (document && smooth) {
          const slideTarget = target || '#mediapress';

          const nodeTarget = document.querySelector(slideTarget);

          if (nodeTarget) {
            nodeTarget.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
          router.push(href);
        }
      }}
      sx={
        isBlackNovember && {
          backgroundColor: '#fbc02d',
          color: '#161616',
          ':hover': {
            backgroundColor: '#BD9020',
          },
        }
      }
    >
      {text}
    </Button>
  );
}
